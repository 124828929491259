import type { EventProperties, TrackingEventName } from '@hiretalk/constants/tracking-events'
import debounce from 'lodash/debounce'
import Clarity from '@microsoft/clarity'

type UserTraits = {
  email: string
  company?: string
  companyId?: string
  campaignId?: string
  fullName?: string
}
type Identity = { id: string } & UserTraits
declare const globalThis: {
  rudderanalytics?: {
    identify: (id: string, identity: UserTraits) => void
    track: <T extends TrackingEventName>(eventName: T, properties: EventProperties[T]) => void
    getUserTraits: () => UserTraits
  }
}

// Extend the Window interface
declare global {
  interface Window {
    clarity?: any // Replace 'any' with the correct type if known
  }
}

const debouncedIdentify = debounce((identity: Identity) => {
  if (globalThis.rudderanalytics) {
    const { id, ...traits } = identity
    const currentTraits = globalThis.rudderanalytics.getUserTraits()

    const { isDifferent } = getDifferencesAndCheck(traits, currentTraits)

    if (isDifferent) {
      globalThis.rudderanalytics.identify(id, traits)
    }
  }
}, 2000) // 3000 ms debounce time

export function setIdentity(identity: Identity): void {
  debouncedIdentify(identity)
  if (!window?.clarity) return
  if (Clarity?.identify && identity.email) {
    Clarity.identify(identity.email)
  }
}

function getDifferencesAndCheck(
  traits: UserTraits,
  currentTraits: UserTraits,
): { isDifferent: boolean; fields: Partial<UserTraits> } {
  const keysToCompare: Array<keyof UserTraits> = ['email', 'company', 'companyId', 'campaignId', 'fullName']
  let isDifferent = false
  const fields = keysToCompare.reduce((diff, key) => {
    if (traits[key] !== currentTraits[key]) {
      diff[key] = traits[key]
      isDifferent = true
    }
    return diff
  }, {} as Partial<UserTraits>)

  return { isDifferent, fields }
}

export function trackEvent<T extends TrackingEventName>(eventName: T, properties: EventProperties[T]): void {
  if (globalThis.rudderanalytics) {
    globalThis.rudderanalytics.track(eventName, properties)
  }
  if (!window?.clarity) return
  if (Clarity && Clarity?.event) {
    Clarity.event(eventName)
  }
}
