'use client'

import { useEffect } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import { rudderInitialize } from '@hiretalk/rudderstackInitialize'
import { setIdentity } from '@hiretalk/libraries/trackingUtils'
import Clarity from '@microsoft/clarity'

declare const globalThis: {
  rudderanalytics?: {
    page: () => void
  }
}

export default function Rudderstack({ isRender }: { isRender: boolean }) {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    if (globalThis.rudderanalytics) {
      globalThis.rudderanalytics.page()
    }
    if (isRender) {
      console.log('Clarity init')
      Clarity.init('pi2noervhg')
    }
    //Clarity.init('YOUR_CLARITY_PROJECT_ID');
    // You can now use the current URL
    // ...
  }, [pathname, searchParams])

  useEffect(() => {
    rudderInitialize()
  }, [])

  return <div></div>
}

type CompanyIdentity = {
  id: string
  email: string
  company?: string
  companyId?: string
}

type CandidateIdentity = {
  id: string
  email: string
  campaignId?: string
  fullName?: string
}

export function RudderstackCompanyIdentify({ identity }: { identity: CompanyIdentity }) {
  useEffect(() => {
    if (identity) {
      setIdentity(identity)
    }
  }, [identity])

  return <></>
}

export function RudderstackCandidateIdentify({ identity }: { identity: CandidateIdentity }) {
  useEffect(() => {
    if (identity) {
      setIdentity(identity)
    }
  }, [identity])

  return <></>
}
